<template>
  <v-container fluid v-if="!isLoading">
    <v-row>
      <v-col>
        <v-text-field
            dense
            label="Texto corto"
            outlined
            autocomplete="new"
            counter
            maxlength="200"
            v-model="misDatos.reco_ptexto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="5">
        <v-text-field
            dense
            label="Descuento"
            outlined
            autocomplete="new"
            counter
            maxlength="15"
            v-model="misDatos.reco_pdescuento"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="5">
        <div style="width:100%" class="text-center">
          <v-switch
              class="mx-4 mt-1"
              v-model="misDatos.reco_pmostrar"
              inset
              color="red"
              label="Mostrar en portada"
          ></v-switch>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="5" v-if="misDatos.fichero==null || misDatos.fichero.refi_id==null">
        <v-file-input dense outlined label="Subir imagen" accept="image/png, image/jpeg, image/svg+xml" @change="onChangeAdjunto($event)"></v-file-input>
      </v-col>
      <v-col v-else>
        <span class="body-1 mr-2">Imagen:</span>
        <v-chip label color="primary" outlined @click="onClickDescargarAdjunto">
          {{ misDatos.fichero.refi_name }}
        </v-chip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn elevation="2" icon small color="error" class="ml-2" v-on="on" @click="onClickEliminarAdjunto">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <span>Eliminar imagen</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-4 " cols="12" md="12">
        <v-btn
            class="mr-4 float-right"
            color="primary"
            @click="onClickBtnGuardar"
        >guardar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { cloneDeep } from "lodash";
import { authHeader } from "@/_helpers";
import { config } from "@/_config";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "FichaDetallePor",
  props: ["datos"],
  data: () => ({
    isLoading: true,
    misDatos: null,
  }),
  watch: {
    datos(nuevo) {
      this.init(nuevo);
    },
  },
  methods:{
    ...mapActions("comun", ["setSnackBar"]),
    ...mapMutations("records", ["SET_RECORDS_RELOAD"]),
    init(param){
      if(param)
        this.misDatos = cloneDeep(param);
      else
        this.misDatos = cloneDeep(this.datos);
      const ficheros=this.misDatos.record_files.filter(f => f.refi_type===2);
      if(ficheros.length>0)
        this.misDatos['fichero']=ficheros[0];
      else
        this.misDatos['fichero']=null;
    },
    onChangeAdjunto(file){
      this.misDatos.fichero={refi_id: null, file: file};
    },
    onClickEliminarAdjunto(){
      const miConfig = {
        headers: {
          "Content-Type": "application/json",
          ...authHeader()
        }
      };
      const url = `${config.apiUrl}/adjunto/${this.misDatos.fichero.refi_id}`;

      axios
          .delete(url, miConfig)
          .then(response => {
            const miSnack = {
              mostrar: true,
              texto: "Imagen eliminada correctamente",
              color: "success",
            };
            this.setSnackBar(miSnack);
            this.SET_RECORDS_RELOAD(true);
            this.$emit("emitActualizarPor", response.data.data.record);
          })
          .catch(error => {
            console.log(error);
          });
    },
    onClickDescargarAdjunto(){
      const miConfig = {
        headers: {
          "Content-Type": "application/json",
          ...authHeader()
        },
        responseType: 'blob'
      };
      const url = `${config.apiUrl}/adjunto/${this.misDatos.fichero.refi_id}`;

      axios
          .get(url, miConfig)
          .then(response => {
            const type = response.headers["content-type"];
            const url = window.URL.createObjectURL(new Blob([response.data], { type }));
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            //link.setAttribute('download', this.misDatos.fichero.refi_name);
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch(error => {
            console.log(error);
          });
    },
    onClickBtnGuardar(){
      const formData = new FormData();
      formData.append('datos', JSON.stringify(this.misDatos));

      if(this.misDatos.fichero!=null && this.misDatos.fichero.file!=null)
        formData.append('fichero', this.misDatos.fichero.file);

      const miConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader()
        },
      };
      const url = `${config.apiUrl}/setPortada`;

      axios
          .post(url, formData, miConfig)
          .then(response => {
            const miSnack = {
              mostrar: true,
              texto: "Datos guardados correctamente",
              color: "success",
            };
            this.setSnackBar(miSnack);
            this.SET_RECORDS_RELOAD(true);
            this.$emit("emitActualizarPor", response.data.data.record);
          })
          .catch(error => {
            console.log(error);
          });
    }
  },
  mounted() {
    this.init();
    this.isLoading = false;
  }
}
</script>
